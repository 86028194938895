<template>
  <b-modal
    id="userSelect"
    v-model="modalData.visible"
    :size="modalData.size"
    modal-class="modal-fill-in"
    hide-header
    hide-footer
  >
    <p class="text-dark text-large mb-3">Select {{ modalData.title }}</p>
    <b-input-group class="mb-3">
      <dc-user-select
        style="width: 80%"
        :filter="modalData.filter"
        :selected.sync="user"
      />
      <b-input-group-append>
        <b-btn variant="primary" @click="selected(user)"> Save </b-btn>
      </b-input-group-append>
    </b-input-group>
    <!-- <div class="text-center text-right text-white opacity-50">Select Project Manager</div> -->
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import userSelect from '@/components/users/UserSelect';

export default {
  name: 'UserSelectModal',
  components: {
    'dc-user-select': userSelect,
  },
  data: () => ({
    user: null,
  }),
  computed: {
    ...mapGetters({
      modalData: 'modals/userSelectModal',
    }),
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId !== 'userSelect') return;
      const {
        data: { selected = null },
      } = this.modalData;
      console.log('OPENED', selected);
      this.user = selected;
    });
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId !== 'userSelect') return;
      this.close({ modal: modalId });
    });
  },
  methods: {
    ...mapActions({
      closeModal: 'modals/CLOSE_MODAL',
      update: 'projects/UPDATE_PROJECT',
    }),
    async selected(value) {
      const {
        data: { projectID, key },
      } = this.modalData;

      if (!projectID || !key) {
        this.$toast.error('Missing Project ID');
        return this.close();
      }

      await this.update({
        projectID,
        update: {
          [`contacts.${key}`]: value,
        },
      });
      // this.$emit('selected', value);
      this.close();
    },
    close() {
      this.user = null;
      this.closeModal({ modal: 'userSelect' });
    },
  },
};
</script>
