<template>
  <b-sidebar
    id="project-filters"
    v-model="isVisible"
    right
    lazy
    backdrop
    bg-variant="white"
    text-variant="dark"
    shadow
  >
    <template #header="{ hide }">
      <div class="flex-grow-1 pt-2">
        <h4 class="font-weight-normal">
          Filters
          <b-button
            variant="outline-secondary"
            size="sm"
            class="float-right"
            @click="reset(hide)"
          >
            Reset Filters
          </b-button>
        </h4>
      </div>
    </template>
    <div class="py-2 px-3">
      <b-form-group
        v-slot="{ ariaDescribedby }"
        label="Project Status"
        label-for="project-status"
      >
        <b-form-radio-group
          id="project-status"
          v-model="filters.completed"
          :options="options.status"
          button-variant="outline-secondary w-100"
          :aria-describedby="ariaDescribedby"
          name="project-status"
          class="d-flex"
          buttons
        />
      </b-form-group>

      <b-form-group
        v-slot="{ ariaDescribedby }"
        label="Watched"
        label-for="watchers"
      >
        <b-form-radio-group
          id="watchers"
          v-model="filters.watchers"
          :options="options.watchers"
          button-variant="outline-secondary w-100"
          :aria-describedby="ariaDescribedby"
          name="radios-btn-default"
          class="d-flex"
          buttons
        />
      </b-form-group>

      <b-form-group label="Operations Lead" label-for="pm">
        <dc-user-select :selected.sync="filters.lead" filter="pm" />
      </b-form-group>

      <b-form-group label="Engineering Lead" label-for="pm">
        <dc-user-select :selected.sync="filters.engineer" filter="eng" />
      </b-form-group>

      <b-form-group label="Customer" label-for="customer">
        <dc-customer-select :selected.sync="filters.customer" />
      </b-form-group>

      <b-form-group
        v-slot="{ ariaDescribedby }"
        label="Systems Defined"
        label-for="systems"
      >
        <b-form-radio-group
          id="systems"
          v-model="filters.systems"
          :options="options.systems"
          button-variant="outline-secondary w-100"
          :aria-describedby="ariaDescribedby"
          name="radios-btn-default"
          class="d-flex"
          buttons
        />
      </b-form-group>
    </div>

    <template #footer="{ hide }">
      <div class="d-flex align-items-center pb-4 px-3">
        <b-button variant="primary rounded-pill" block size="md" @click="hide">
          Show {{ queryResultLength }} Results
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
// import { isEqual } from 'lodash'

import userSelect from '@/components/users/UserSelect';
import customerSelect from '@/components/CustomerSelect';

export default {
  name: 'ProjectQuerySidebar',
  components: {
    'dc-user-select': userSelect,
    'dc-customer-select': customerSelect,
  },
  props: {
    visible: Boolean,
  },
  data: () => ({
    filters: {
      completed: false,
      lead: null,
      createdBy: null,
      customer: null,
      systems: null,
      watchers: null,
    },
    options: {
      status: [
        { text: 'Open', value: false },
        { text: 'Closed', value: true },
        { text: 'All', value: null },
      ],
      systems: [
        // { text: 'Included', value: true },
        { text: 'Missing', value: 0 },
        { text: 'All', value: null },
      ],
      watchers: [
        { text: 'Watching', value: true },
        { text: 'All', value: null },
      ],
    },
  }),

  computed: {
    ...mapGetters({
      query: 'projects/query',
      queryResultLength: 'projects/queryResultLength',
    }),
    isVisible: {
      get() {
        return this.visible;
      },
      set(evt) {
        if (!evt) return this.toggleSidebarVisible(evt);
        // update filters from external query changes
        for (const key of Object.keys(this.filters)) {
          this.filters[key] =
            typeof this.query[key] === 'undefined' ? null : this.query[key];
        }
      },
    },
  },

  watch: {
    filters: {
      handler(query) {
        const q = { ...query };
        for (const [key, value] of Object.entries(q)) {
          if (value === null) delete q[key];
        }
        // this.$router.replace({ query })
        this.queryProjects(q);
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      queryProjects: 'projects/QUERY_PROJECTS',
    }),
    ...mapMutations({
      toggleSidebarVisible: 'projects/toggleSidebarVisible',
    }),
    reset($event) {
      this.filters = {
        completed: false,
        lead: null,
        engineer: null,
        createdBy: null,
        customer: null,
        systems: null,
      };
      $event();
    },
  },
};
</script>

<style lang="scss"></style>
