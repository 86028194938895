<template>
  <!-- <b-form-select v-model="select" :options="options" @change="$emit('update:selected', select);" v-if="options.length">
    </b-form-select> -->
  <v-select
    v-if="options.length"
    v-model="select"
    class=""
    :options="options"
    :reduce="(user) => user.value"
    :clearable="false"
    @option:selected="$emit('update:selected', select)"
  >
    <template #open-indicator="{ attributes }">
      <span v-bind="attributes" class="ion ion-md-arrow-dropdown px-2" />
    </template>
  </v-select>
</template>

<script>
import VSelect from 'vue-select';
// import 'vue-select/dist/vue-select.css'
import { mapGetters } from 'vuex';
export default {
  name: 'UserSelect',
  components: {
    VSelect,
  },
  props: {
    filter: {
      type: String,
      default: 'all',
    },
    selected: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      select: null,
    };
  },
  computed: {
    ...mapGetters('users', {
      projectManagers: 'GET_PROJECT_MANAGERS',
      engineers: 'GET_ENGINEERING_LEADS',
      users: 'GET_USERS',
    }),
    options() {
      const defaultOptions = {
        value: null,
        label: 'Please Select A User',
        disabled: false,
      };
      if (!this.users || !this.projectManagers) return [defaultOptions];

      switch (this.filter) {
        case 'pm': {
          const options = [...this.projectManagers].map(
            ({ uid: value, displayName: label }) => ({ value, label })
          );
          return [defaultOptions, ...options];
        }

        case 'eng': {
          const options = [...this.engineers].map(
            ({ uid: value, displayName: label }) => ({ value, label })
          );
          return [defaultOptions, ...options];
        }

        default: {
          const options = [...this.users].map(
            ({ uid: value, displayName: label }) => ({ value, label })
          );
          return [defaultOptions, ...options];
        }
      }
    },
  },

  async created() {
    this.select = this.selected;
  },
};
</script>

<style lang="scss">
// .vs__selected {
//   line-height: 1.8;
// }
</style>
