// import firebase from '@/services/firebase'
import {
  ref,
  getStorage,
  uploadBytes,
  deleteObject,
  getDownloadURL,
} from 'firebase/storage';

import { doc, setDoc } from 'firebase/firestore';
import { getExtension } from 'mime/lite';
import { uuid } from '@/services/utils';
import { app } from '@/services/firebase';
import { fileCollection } from '@/services/firebase/db';

export const storage = getStorage(app);

export const dropzoneFileUpload = async (upload, _metadata) => {
  if (!upload) {
    return null;
  }
  const file = upload;
  const metadata = {
    contentType: upload.type,
  };
  const fileName = upload.name.replace(/([^a-z0-9.]+)/gi, '-');
  const _uuid = uuid();
  try {
    const fileRef = ref(storage, `uploads/${_uuid}_${fileName}`);
    await uploadBytes(fileRef, file, metadata);
    const downloadURL = await getDownloadURL(fileRef);
    const fileObj = {
      name: fileName,
      src: downloadURL,
      uuid: _uuid,
    };
    const docRef = doc(fileCollection, _uuid);
    await setDoc(docRef, {
      ..._metadata,
      uuid: _uuid,
      file: fileObj,
    });
    return fileObj;
  } catch (error) {
    Promise.reject(error);
  }
};

export const uploadBlob = async (blob, directory, name) => {
  if (!blob || !directory) return;
  const metadata = {
    contentType: blob.type,
  };
  const fileName = formatFileName(blob, name);
  try {
    const fileRef = ref(storage, `${directory}/${fileName}`);
    await uploadBytes(fileRef, blob, metadata);
    return getDownloadURL(fileRef);
  } catch (error) {
    console.log(error);
  }
};

export const uploadImage = (file) => {
  const date = new Date();
  const fileName = date.getTime() + '-' + file.name;
  const metadata = {
    contentType: 'image/jpeg',
  };

  const uploadTask = storage
    .ref()
    .child('images/' + fileName)
    .put(file, metadata);

  return uploadTask.then((snapshot) => {
    return snapshot.ref.getDownloadURL();
  });
};

// TODO: remove file collection refs
export const removeObject = (file) => deleteObject(ref(storage, file));

const slugify = (str) => str.toLowerCase().replace(/([^a-z0-9.]+)/gi, '-');
// .replace(/\s+/g, '-').replace(/[^\w-]+/g, '')

export const formatFileName = (file, name) => {
  if (!name) return `${uuid()}_${slugify(file.name)}`;
  return `${uuid()}_${slugify(name)}.${getExtension(file.type)}`;
};

export default storage;
