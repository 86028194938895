import Vue from 'vue';
import App from './App';
import store from '@/store';
import router from '@/router';

// firebase dependencies
import firebase from '@/services/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '@/services/firebase/auth';

import BootstrapVue from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import Vue2Filters from 'vue2-filters';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import VCalendar from 'v-calendar';
import InstantSearch from 'vue-instantsearch';

import Rollbar from 'rollbar';

// import { ClientTable } from 'vue-tables-2'

// import 'vue-easytable/libs/theme-default/index.css' // import style
// import VueEasytable from 'vue-easytable' // import library

import globals from './globals';
import Popper from 'popper.js';

// custom global filters
import '@/filters';
import '@/registerServiceWorker';

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(firebase);
Vue.use(Vue2Filters);
Vue.use(InstantSearch);

Vue.use(Toast, {
  timeout: 2000,
  position: 'bottom-right',
  hideProgressBar: true,
  maxToasts: 3,
});

Vue.use(VCalendar, {
  componentPrefix: 'dc',
});

// Global RTL flag
Vue.mixin({
  data: globals,
});

Vue.prototype.$rollbar = new Rollbar({
  accessToken: '72b1425924ee492399224935ed5c0150',
  captureUncaught: true,
  captureUnhandledRejections: true,
});

Vue.config.errorHandler = (err, vm) => {
  vm.$rollbar.error(err);
  throw err;
};

// Wrap the vue instance in a Firebase onAuthStateChanged method
// This stops the execution of the navigation guard 'beforeEach'
// method until the Firebase initialization ends
let app = null;
onAuthStateChanged(auth, async (user) => {
  console.log('👣 FB Auth state has changed');
  if (user) {
    await store.dispatch('auth/AUTO_SIGN_IN', user);
  }
  if (!app) {
    /* eslint-disable no-new */
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount('#app');
  }
});
