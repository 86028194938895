var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{attrs:{"custom":"","to":_vm.to,"replace":_vm.replace,"append":_vm.append,"exact":_vm.exact},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('div',{staticClass:"sidenav-item",class:{
      active: _vm.active || isActive || isExactActive,
      disabled: _vm.disabled,
    }},[_c('a',{staticClass:"sidenav-link",class:_vm.linkClass,attrs:{"href":href},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[(_vm.icon)?_c('i',{staticClass:"sidenav-icon",class:_vm.icon}):_vm._e(),_vm._v(" "),_c('div',[_vm._t("default")],2),_vm._v(" "),(_vm.badge)?_c('div',{staticClass:"pl-1 ml-auto"},[_c('div',{staticClass:"badge",class:_vm.badgeClass},[_vm._v(_vm._s(_vm.badge))])]):_vm._e()])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }