import store from '@/store';

export const userCan = (ability) => {
  if (!store) {
    return;
  }
  const roles = store.getters['users/GET_USER_ROLES'];

  const { pm, admin, engineer } = roles;

  switch (ability) {
    // user can edit system progress inside a project
    case 'editSystem':
      if (roles && (pm || admin || engineer)) return true;
      break;

    case 'addProject':
      if (roles && admin) return true;
      break;

    case 'editBilling':
      if (roles && (pm || admin)) return true;
      break;

    case 'editComments':
      if (roles && admin) return true;
      break;

    case 'editProject':
      if (roles && (pm || admin)) return true;
      break;

    case 'removeProject':
      if (roles && admin) return true;
      break;

    case 'modifyWatchers':
      if (roles && (pm || admin)) return true;
      break;

    // user can view executive dashboards
    case 'viewDashboards':
      if (roles && (pm || admin)) return true;
      break;

    case 'viewMaterials':
      if (roles && (pm || admin)) return true;
      break;

    // user can view application Settings
    case 'viewSettings':
      if (roles && admin) return true;
      break;

    default:
      break;
  }
  // console.log(`AUTH REQUEST | ${ability} is ${access}`)
  return false;
};
