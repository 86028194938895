import Vue from 'vue';
import { format, formatDistanceToNowStrict } from 'date-fns';
import { format as d3Format, precisionFixed, formatSpecifier } from 'd3-format';
// import isValid from 'date-fns/is_valid'

/**
 * filter function - date
 * @param {string} date object or firebase server timestamp
 * @param {string} format string usable by datefns
 * @return {string} date string as format - default MMMM do yyyy
 */
Vue.filter('date', (date, dateFormat = 'MMMM do yyyy') => {
  if (!date) return '';
  switch (typeof date) {
    case 'object': {
      if (date instanceof Date) return date;
      return format(date.toDate(), dateFormat);
    }
    case 'string': {
      const valid = new Date(date).getTime();
      if (!valid) return date;
      return format(new Date(date), dateFormat);
    }
    case 'number': {
      const valid = new Date(date).getTime();
      if (!valid) return date;
      return format(date, dateFormat);
    }
  }
});
// date strings used
// shortDate ='M/d/yy'
// longDate = 'MMM do yyyy h:mm a'

// Vue.filter('number', number => {
//   if (typeof number === 'number') {
//     return numeral(number).format('0.[00]')
//   } else {
//     return 'N/A'
//   }
// })

/**
 * filter function - timeAgo
 * @param {string} date object or firebase server timestamp
 * @return {string} date string as distance from now
 */
Vue.filter('timeAgo', (date, units) => {
  if (!date) return '';
  switch (typeof date) {
    case 'object': {
      if (!units) {
        return formatDistanceToNowStrict(date.toDate(), {
          addSuffix: true,
        });
      }
      return formatDistanceToNowStrict(date.toDate(), {
        addSuffix: true,
        unit: units,
      });
    }

    case 'string':
      // TODO: Add a check for timestamps saved as an epoch string and handle correctly
      return date;
  }
});

/**
 * filter function - age
 * @param {string} date object or firebase server timestamp
 * @param {string} format string usable by datefns
 * @return {string} date string as format - default MMMM do yyyy
 */
Vue.filter('age', (date, unit = 'day') => {
  if (!date) return '';
  switch (typeof date) {
    case 'object':
      return formatDistanceToNowStrict(date.toDate(), {
        unit,
      });
    case 'string':
      // TODO: Add a check for timestamps saved as an epoch string and handle correctly
      return date;
    case 'number':
      return formatDistanceToNowStrict(date, {
        unit,
      });
  }
});

/**
 * filter function - username
 * @param {string} DisplayName from AzureAD - Joe John
 * @return {string} username as first initial last name - '@jjohn'
 */
Vue.filter('username', (string) => {
  if (!string) return '';
  const [first, last] = string.split(' ');
  const firstInitial = first.split('')[0];
  return `@${firstInitial}${last}`.toLowerCase();
});

Vue.filter('percentage', (number) => {
  if (typeof number === 'number') {
    const s = formatSpecifier('~p');
    s.precision = precisionFixed('0.001');
    return d3Format(s)(number / 100);
  } else {
    return 'N/A';
  }
});

Vue.filter('truncate', (text, length, clamp) => {
  clamp = clamp || '...';
  const node = document.createElement('div');
  node.innerHTML = text;
  const content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
});
