<template>
  <b-navbar
    toggleable="lg"
    class="layout-navbar bg-navbar-theme align-items-lg-center container-p-x"
  >
    <!-- Sidenav toggle -->
    <b-navbar-nav v-if="sidenavToggle" class="align-items-lg-center mr-lg-4">
      <a
        class="nav-item nav-link px-0 ml-2 ml-lg-0"
        href="javascript:void(0)"
        @click="toggleSidenav"
      >
        <i class="ion ion-md-menu text-large align-middle" />
      </a>
    </b-navbar-nav>

    <!-- Brand demo (see demo.css) -->
    <b-navbar-brand class="app-brand demo d-lg-none py-0 mr-4">
      <span class="app-brand-text demo font-weight-normal ml-2"> DIVPRO </span>
    </b-navbar-brand>

    <b-navbar-toggle target="app-layout-navbar" />

    <b-collapse id="app-layout-navbar" is-nav>
      <!-- Divider -->
      <hr class="d-lg-none w-100 my-2" />

      <b-navbar-nav class="align-items-lg-center">
        <search-bar />
      </b-navbar-nav>

      <b-navbar-nav
        v-if="authenticated && user.profile"
        class="align-items-lg-center ml-auto"
      >
        <b-nav-item-dropdown right class="navbar-user" lazy>
          <template slot="button-content">
            <span
              class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
            >
              <b-avatar
                variant="default"
                rounded
                :src="user.profile.photoURL"
                :text="initials"
              />
            </span>
          </template>

          <b-dd-item>
            <i class="ion ion-ios-person text-lightest mr" />My profile
          </b-dd-item>
          <!-- <b-dd-item><i class="ion ion-ios-mail text-lightest"></i> &nbsp; Messages</b-dd-item> -->
          <!-- <b-dd-item>
            <i class="ion ion-md-settings text-lightest mr" />Account settings
          </b-dd-item> -->
          <b-dd-divider />
          <b-dd-item @click="signOut()">
            <i class="ion ion-ios-log-out text-danger mr" />Log Out
          </b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import SearchBar from '../components/SearchBar.vue';
export default {
  name: 'AppLayoutNavbar',

  components: {
    SearchBar,
  },

  props: {
    sidenavToggle: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      authenticated: 'auth/IS_AUTHENTICATED',
      user: 'users/user',
    }),
    initials() {
      if (!this.user || !this.user.profile || !this.user.profile.displayName) {
        return '';
      }
      const [first, last] = this.user.profile.displayName.split(' ');
      return `${first.charAt(0)}${last.charAt(0)}`;
    },
  },

  methods: {
    ...mapActions('auth', {
      signOut: 'SIGN_OUT',
    }),

    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    },
  },
};
</script>

<style lang="scss">
.ion.mr {
  margin-right: 12px;
}
.navbar-user {
  .nav-link {
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
