<template>
  <div id="app">
    <!-- <b-alert :show="updateExists" variant="danger" class="mb-0">
      DIVPRO has a new version available,
      <a href="#" class="alert-link" @click.prevent="refreshApp">Update Now</a>
    </b-alert> -->
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import update from '@/mixins/update'

export default {
  name: 'App',
  metaInfo: {
    title: 'Index',
    titleTemplate: 'DIVPRO | %s',
  },
  // mixins: [update],
  computed: {
    ...mapGetters({
      error: 'app/error',
    }),
  },
  watch: {
    error() {
      if (!this.error) return;
      this.$toast.error(JSON.stringify(this.error), {
        timeout: false,
      });
      this.clearError();
    },
  },
  methods: {
    ...mapActions({
      clearError: 'app/CLEAR_ERROR',
    }),
  },
};
</script>

<style src="@/vendor/styles/bootstrap.scss" lang="scss"></style>
<style src="@/vendor/styles/appwork.scss" lang="scss"></style>
<style src="@/vendor/styles/theme-corporate.scss" lang="scss"></style>
<style src="@/vendor/styles/colors.scss" lang="scss"></style>
<style src="@/vendor/styles/uikit.scss" lang="scss"></style>
<style src="@/style.scss" lang="scss"></style>
