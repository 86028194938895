import AppLayout from '@/layout/AppLayout';

const routes = [
  {
    path: '/app/reports',
    component: AppLayout,
    children: [
      {
        name: 'ProjectOverview',
        path: 'project-overview',
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/views/reports/ProjectOverview'),
      },
      {
        name: 'EngineeringOverview',
        path: 'engineering-overview',
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/views/reports/EngineeringOverview'),
      },
      {
        name: 'EngineeringRequests',
        path: 'engineering-requests',
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/views/requests/engineering/RequestList'),
      },
      {
        name: 'CalendarView',
        path: 'calendar-overview',
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/views/reports/CalendarView'),
      },
    ],
  },
];

export default routes;
