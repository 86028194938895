/* global process */

import Vue from 'vue';
import Vuex from 'vuex';

import app from './modules/app';
import auth from './modules/auth';
import users from './modules/users';
import modals from './modules/modals';
import notes from './modules/notes';
import customers from './modules/customers';
import projects from './modules/projects';
import requests from './modules/requests';
import punchlist from './modules/punchlist';
import systems from './modules/systems';
import materials from './modules/materials';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    app,
    auth,
    users,
    modals,
    notes,
    customers,
    projects,
    punchlist,
    requests,
    systems,
    materials,
  },
  strict: debug,
});
