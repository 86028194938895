import { app } from '@/services/firebase';
import {
  serverTimestamp,
  collection,
  collectionGroup,
  getFirestore,
} from '@firebase/firestore';

export const fstore = getFirestore(app);

// try {
//   db.enablePersistence()
// } catch (error) {
//   throw new Error(error)
// }

export const metadataCollection = collection(fstore, 'metadata');

// export collection refs
export const userCollection = collection(fstore, 'users');
export const projectCollection = collection(fstore, 'projects');
export const activityCollection = collection(fstore, 'activities');
export const productCollection = collection(fstore, 'products');
export const tasksCollection = collection(fstore, 'tasks');
export const customerCollection = collection(fstore, 'customers');
export const ticketCollection = collection(fstore, 'tickets');
export const fileCollection = collection(fstore, 'files');
export const bomCollection = collection(fstore, 'boms');

// export subcollection refs

export const taskCompletionCollection = (projectID) => {
  if (!projectID) return collectionGroup(fstore, 'taskCompletion');
  return collection(fstore, 'projects', projectID, 'taskCompletion');
};

export const systemsCollection = (projectID) => {
  if (!projectID) return collectionGroup(fstore, 'systems');
  return collection(fstore, 'projects', projectID, 'systems');
};

export const notesCollection = (projectID) => {
  if (!projectID) return collectionGroup(fstore, 'notes');
  return collection(fstore, 'projects', projectID, 'notes');
};

export const punchListCollection = (projectID) => {
  if (!projectID) return collectionGroup(fstore, 'punchList');
  return collection(fstore, 'projects', projectID, 'punchList');
};

export const historyCollection = (projectID) => {
  if (!projectID) return collectionGroup(fstore, 'history');
  return collection(fstore, 'projects', projectID, 'history');
};

export const ts = serverTimestamp();
export const timestamp = serverTimestamp();

// export const FieldValue = firebase.firestore.FieldValue;
export default fstore;

// export default {
//   db,
//   userCollection,
//   projectCollection,
//   productCollection,
//   tasksCollection,
//   customerCollection,
//   ticketCollection
// }
