import {
  doc,
  query,
  orderBy,
  getDoc,
  getDocs,
  onSnapshot,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore';

import { userCollection } from '@/services/firebase/db';
import { uploadBlob } from '@/services/firebase/storage';
import { checkUID28, removeNullProperties } from '@/utilities';

// userStore
const state = {
  userID: null,
  user: {
    uid: null,
    profile: {},
  },
  users: [],
};

const getters = {
  user: (state) => state.user,
  // TODO: remove old ref to USERID
  GET_USERID: (state) => state.user.uid,
  GET_USER_ID: (state) => state.userID,
  GET_USER_ROLES: (state) => state.user.profile.roles || {},
  userInfo: (state) => (uid) => {
    // UID is not available or UID is not a valid firestore uid
    if (!checkUID28(uid)) return { displayName: 'Invalid UID', uid };
    return (
      state.users.find((u) => u.uid === uid) || {
        displayName: 'Unknown UID',
        uid,
      }
    );
  },
  GET_PROJECT_MANAGERS: (state) =>
    state.users.filter((u) => u.active && u.roles && u.roles.pm) || [],
  GET_ENGINEERING_LEADS: (state) =>
    state.users.filter((u) => u.active && u.roles && u.roles.engineer) || [],
  GET_USERS: (state) => state.users.filter((u) => u.active) || [],
  GET_ARCHIVED_USERS: (state) => state.users.filter((u) => !u.active) || [],
};

const actions = {
  // Update User Information
  async SET_USER_DATA({ commit }, { user, profile: providerProfile }) {
    if (!user) return;
    const { uid, displayName, email, phoneNumber, emailVerified } = user;

    // Set Logged in User Context
    commit('SET_USER_ID', uid);

    let profileInfo = {
      uid,
      active: true,
      displayName,
      email,
      phoneNumber,
      emailVerified,
    };

    try {
      this._vm.$rollbar.configure({
        payload: {
          person: {
            id: uid, // required
            username: user.displayName,
            email: user.email,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }

    // loop through object to not overwrite firestore data with nulls
    profileInfo = removeNullProperties(profileInfo);

    // Replaced with removeNullProperties
    // Object.keys(profileInfo).forEach(
    //   (k) => profileInfo[k] === null && delete profileInfo[k]
    // );

    // additional info from MS Graph API
    if (providerProfile) {
      const additionalInfo = {
        jobTitle: providerProfile.jobTitle,
        mobilePhone: providerProfile.mobilePhone,
        officeLocation: providerProfile.officeLocation,
        azureID: providerProfile.id,
      };

      profileInfo = {
        ...profileInfo,
        ...additionalInfo,
      };
    }
    const docRef = doc(userCollection, uid);
    try {
      const userDoc = await getDoc(docRef);
      const created = serverTimestamp();
      if (!userDoc.exists()) {
        // NEW USER
        await setDoc(docRef, { created }, { merge: true });
      }
      await updateDoc(docRef, {
        ...profileInfo,
        updated: serverTimestamp(),
      });
    } catch (error) {
      throw new Error(error);
    }
    onSnapshot(docRef, (snap) => {
      console.log('USER UPDATE');
      const profileData = snap.data();
      console.log(profileData);
      commit('SET_USER_PROFILE', profileData);
    });
  },

  async GET_USER_AVATAR({ commit }, { credential, user }) {
    if (!credential || !credential.accessToken) return;
    const Authorization = credential.accessToken;
    const headers = { Authorization };
    try {
      const response = await fetch(
        'https://graph.microsoft.com/v1.0/me/photos/360x360/$value',
        {
          headers,
        }
      );
      // graph API returns 200 only when image available
      if (response.status !== 200) return;
      const blob = await response.blob();
      const photoURL = await uploadBlob(blob, 'images', user.uid);
      const docRef = doc(userCollection, user.uid);
      await updateDoc(docRef, { photoURL });
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  async GET_USERS({ commit }) {
    try {
      // query all users (inactive as well for metadata)
      const q = query(userCollection, orderBy('displayName'));
      const querySnap = await getDocs(q);
      // build user array from profile data
      const users = [];
      for (const doc of querySnap.docs) {
        users.push(doc.data());
      }
      // set users state (freeze in mutation)
      commit('SET_USERS', users);
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },
};

const mutations = {
  SET_USERS: (state, users) => {
    state.users = users.map((user) => {
      if (user.displayName) {
        const [first, last] = user.displayName.split(' ');
        user.initials = `${first.charAt(0)}${last.charAt(0)}`;
      }
      return Object.freeze({ ...user });
    });
  },
  SET_USER_ID: (state, uid) => {
    state.user.uid = uid;
    state.userID = uid;
  },
  SET_USER_PROFILE: (state, profile) => {
    state.user.profile = profile;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
