<template>
  <div class="layout-wrapper layout-2">
    <div class="layout-inner">
      <app-layout-sidenav />
      <div class="layout-container">
        <app-layout-navbar />
        <div class="layout-content">
          <div class="router-transitions container-fluid flex-grow-1">
            <router-view />
            <router-view name="modalView" />
          </div>
        </div>
      </div>
    </div>
    <div class="layout-overlay" @click="closeSidenav" />
  </div>
</template>

<script>
import LayoutNavbar from './LayoutNavbar';
import LayoutSidenav from './LayoutSidenav';

export default {
  name: 'AppLayout',
  components: {
    'app-layout-navbar': LayoutNavbar,
    'app-layout-sidenav': LayoutSidenav,
  },

  mounted() {
    this.layoutHelpers.init();
    this.layoutHelpers.update();
    this.layoutHelpers.setAutoUpdate(true);
    this.layoutHelpers.setCollapsed(true);
  },

  beforeDestroy() {
    this.layoutHelpers.destroy();
  },

  methods: {
    closeSidenav() {
      this.layoutHelpers.setCollapsed(true);
    },
  },
};
</script>
