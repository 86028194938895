import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';
import store from '@/store';

import Layout2 from '@/layout/Layout2';

import appRoutes from './appRoutes';
import guestRoutes from './guestRoutes';
import authRoutes from './authRoutes';
import projectRoutes from './projectRoutes';
import reportRoutes from './reportRoutes';
import userRoutes from './userRoutes';
import ticketRoutes from './ticketRoutes';
import materialsRoutes from './materialsRoutes';

Vue.use(Router);
Vue.use(Meta);

const router = new Router({
  base: '/',
  mode: 'history',
  routes: [
    {
      path: '',
      component: Layout2,
      children: [
        {
          path: '',
          redirect: {
            name: 'Login',
          },
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
  ]
    .concat(appRoutes)
    .concat(guestRoutes)
    .concat(authRoutes)
    .concat(projectRoutes)
    .concat(ticketRoutes)
    .concat(reportRoutes)
    .concat(userRoutes)
    .concat(materialsRoutes),
});

router.afterEach(() => {
  // On small screens collapse sidenav
  if (
    window.layoutHelpers &&
    !window.layoutHelpers.isCollapsed() &&
    !window.layoutHelpers.isCollapsed()
  ) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10);
  }
});

// Check before each page load whether the page requires authentication/
// if it does check whether the user is signed into the web app or
// redirect to the sign-in page to enable them to sign-in
router.beforeEach(async (to, from, next) => {
  console.debug(`🚦 Nav from ${from.name} to ${to.name}`);
  // await store.dispatch('auth/INIT_AUTH')
  const isAuthed = store.getters['auth/IS_AUTHENTICATED'];
  const isGuest = store.getters['auth/IS_GUEST'];
  const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);

  if (to.name === 'Login' && isAuthed) {
    if (isGuest) return next({ name: 'GuestDashboard' });
    else return next({ name: 'UserDashboard' });
  }

  if (requiresAuth && !isAuthed) {
    next({
      name: 'Login',
      query: {
        redirectTo: to.path,
      },
    });
  } else if (requiresAuth && isAuthed) {
    next();
  } else {
    next();
  }
});

export default router;
