import Layout2 from '@/layout/Layout2';

const routes = [
  {
    path: '/app/users',
    component: Layout2,
    children: [
      {
        name: 'UserDetails',
        path: ':userID',
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/views/users/Detail'),
      },
    ],
  },
];

export default routes;
