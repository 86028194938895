/* eslint-disable no-console */
// /* global process */

if (navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
}

// import { register } from 'register-service-worker'

// if (process.env.NODE_ENV === 'production') {
//   register(`${process.env.BASE_URL}service-worker.js`, {
//     registered(registration) {
//       setInterval(() => {
//         registration.update()
//       }, 30000)
//     },
//     updated(registration) {
//       console.log('New content is available; please refresh.')
//       document.dispatchEvent(
//         new CustomEvent('swUpdated', {
//           detail: registration
//         })
//       )
//     }
//   })
// }
