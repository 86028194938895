import AppLayout from '@/layout/AppLayout';

const routes = [
  {
    path: '/app/projects',
    component: AppLayout,
    children: [
      {
        name: 'ProjectList',
        path: '',
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/views/projects/List'),
        children: [
          {
            name: 'ProjectCreate',
            path: 'create',
            component: () => import('@/views/projects/Create'),
          },
        ],
      },
      // Individual Project Routes
      {
        path: ':projectId',
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/views/projects/ProjectIndex'),
        children: [
          {
            name: 'ProjectDetail',
            path: '',
            props: true,
            component: () => import('@/views/projects/Detail'),
            children: [
              {
                name: 'PunchlistCreate',
                path: 'punchlist',
                component: () => import('@/views/punchlist/Edit'),
              },
              {
                name: 'PunchlistEdit',
                path: 'punchlist/:itemID',
                component: () => import('@/views/punchlist/Edit'),
              },
              {
                name: 'SystemAdd',
                path: 'systems/add',
                component: () => import('@/views/systems/Create'),
              },
              {
                name: 'SystemEdit',
                path: 'systems/:systemID',
                component: () => import('@/views/systems/Edit'),
              },
              {
                name: 'EngineeringRequest',
                path: 'request/',
                component: () => import('@/views/requests/EngineeringRequest'),
              },
            ],
          },
          {
            name: 'ProjectEngineeringRequests',
            path: 'requests',
            component: () => import('@/views/requests/RequestByProject'),
          },
          {
            name: 'BillOfMaterials',
            path: 'bom',
            component: () => import('@/views/projects/bom/Detail'),
          },
          {
            name: 'ProjectFiles',
            path: 'files',
            component: () => import('@/views/projects/files/FileList'),
          },
          {
            name: 'ProjectActivity',
            path: 'activity',
            component: () =>
              import('@/views/projects/activity/ProjectActivity'),
          },
        ],
      },
      {
        name: 'ProjectExport',
        path: ':projectId/export',
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/views/projects/Export'),
      },
    ],
  },
];

export default routes;
