import { ref, set, onValue, onDisconnect, push } from '@firebase/database';
import {
  signInWithPopup,
  getAdditionalUserInfo,
  OAuthProvider,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { UAParser } from 'ua-parser-js';

import { provider, auth } from '@/services/firebase/auth';
import { realtimeDB, ts } from '@/services/firebase/realtime';
import router from '@/router';

// shorthand for store calls
const root = true;
const { browser, os } = new UAParser().getResult();

const state = {
  authId: null,
  authenticated: false,
  guest: null,
  listener: null,
  error: null,
};

const getters = {
  GET_ERROR: (state) => state.error,
  IS_AUTHENTICATED: (state) => state.authenticated,
  IS_GUEST: (state) => state.guest,
};

const actions = {
  // Firebase Stored Auth State
  AUTO_SIGN_IN: async ({ commit, dispatch }, user) => {
    const tokenResult = await user.getIdTokenResult();
    const guest = tokenResult.signInProvider !== 'microsoft.com';
    commit('SET_AUTHENTICATED', { status: true, guest });
    dispatch('users/SET_USER_DATA', { user }, { root });
    dispatch('SET_FIREBASE_PRESENCE', user);
    dispatch('app/INIT', {}, { root });
  },

  // Azure AD SSO
  SIGN_IN_AZURE: async ({ commit, dispatch }) => {
    try {
      const result = await signInWithPopup(auth, provider);
      const { profile } = getAdditionalUserInfo(result);
      const credential = OAuthProvider.credentialFromResult(result);
      const user = {
        user: result.user,
        profile,
      };
      commit('SET_AUTHENTICATED', { status: true, guest: false });
      dispatch('users/SET_USER_DATA', user, { root });
      dispatch(
        'users/GET_USER_AVATAR',
        { credential, user: result.user },
        { root }
      );
      dispatch('app/INIT', {}, { root });
      router.push('/app/dashboard');
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  // Sign in with Email / Password
  SIGN_IN: async ({ commit, dispatch }, credentials) => {
    // if (state.user.authenticated) { return }

    try {
      // login with email and password
      if (!credentials.email || !credentials.password) {
        throw new Error('Email or Password Missing');
      }
      const result = await signInWithEmailAndPassword(
        auth,
        credentials.email,
        credentials.password
      );
      commit('SET_AUTHENTICATED', { status: true, guest: true });
      dispatch('users/SET_USER_DATA', { user: result.user }, { root });
      dispatch('app/INIT', {}, { root });
      dispatch('SET_FIREBASE_PRESENCE', result.user);
      router.push({
        name: 'GuestDashboard',
      });
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  SIGN_OUT: async ({ state, commit, dispatch }) => {
    if (state.authenticated) {
      await dispatch('app/CLEAR_LISTENER', {}, { root: true });
      commit('SET_AUTHENTICATED', {
        status: false,
        guest: null,
      });
      await signOut(auth);
      if (router.currentRoute.name !== 'Login') {
        router.push({ name: 'Login' });
      }
    }
  },

  SET_FIREBASE_PRESENCE: async (ctx, { uid }) => {
    // utilize the realtime DB to set the user's presence
    const connectionsRef = ref(realtimeDB, `users/${uid}/connections`);
    const lastOnlineRef = ref(realtimeDB, `users/${uid}/lastOnline`);
    const connectedRef = ref(realtimeDB, '.info/connected');
    onValue(connectedRef, (snap) => {
      if (snap.val() === true) {
        const con = push(connectionsRef);
        onDisconnect(con).remove();
        set(con, {
          ts,
          browser,
          os: os.name,
        });
        onDisconnect(lastOnlineRef).set(ts);
      }
    });
  },
};

const mutations = {
  SET_AUTHENTICATED: (state, { status, guest }) => {
    state.authenticated = status;
    state.guest = guest;
  },
  SET_ERROR: (state, error) => {
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
