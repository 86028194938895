import AppLayout from '@/layout/AppLayout';

const routes = [
  {
    path: '/app/materials',
    component: AppLayout,
    children: [
      {
        name: 'bomReview',
        path: '',
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/views/materials/BillOfMaterialsReview'),
        children: [
          {
            name: 'bomEdit',
            path: 'edit/:bomID',
            component: () => import('@/views/materials/BillOfMaterialsEdit'),
          },
          {
            name: 'bomUpload',
            path: 'upload',
            component: () => import('@/views/materials/BillOfMaterialsUpload'),
          },
        ],
      },
    ],
  },
];

export default routes;
