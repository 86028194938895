import { getAuth, OAuthProvider } from 'firebase/auth';

export const auth = getAuth();
export const provider = new OAuthProvider('microsoft.com');

// allows only divconcontrols.onmicrosoft.com
provider.setCustomParameters({
  tenant: '580e9bbc-998f-4c70-bd05-0131f2d2923d',
});

// add aditional oauth scopes
// provider.addScope('mail.read')
// provider.addScope('calendars.read')
