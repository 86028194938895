import AppLayout from '@/layout/AppLayout';

const routes = [
  {
    path: '/app',
    component: AppLayout,
    children: [
      {
        name: 'UserDashboard',
        path: 'dashboard',
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/views/app/Dashboard'),
      },
      {
        path: 'settings',
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/views/settings/SettingsIndex'),
        children: [
          {
            name: 'GeneralSettings',
            path: '',
            props: true,
            component: () => import('@/views/settings/General'),
          },
          {
            name: 'CategorySettings',
            path: 'categories',
            props: true,
            component: () => import('@/views/settings/Categories'),
          },
          {
            name: 'UserSettings',
            path: 'users',
            props: true,
            component: () => import('@/views/settings/Users'),
          },
          {
            name: 'FileLog',
            path: 'files',
            props: true,
            component: () => import('@/views/settings/Files'),
          },
          {
            name: 'ActivityLog',
            path: 'activity',
            props: true,
            component: () => import('@/views/settings/Activity'),
          },
        ],
      },
    ],
  },
];

export default routes;
