import store from '@/store';
import { activityCollection } from '@/services/firebase/db';
import {
  serverTimestamp,
  addDoc,
  query,
  where,
  orderBy,
  getDocs,
  limit,
} from '@firebase/firestore';

export const logger = async (info) => {
  if (!info || !info.type) {
    return false;
  }
  const user = store.getters['users/user'];

  info.user = user.uid;
  info.ts = serverTimestamp();

  return addDoc(activityCollection, info);
};

export const getLogsByProject = async (projectID, limitTo = 10) => {
  if (!projectID) return false;
  const q = query(
    activityCollection,
    where('projectID', '==', projectID),
    orderBy('ts', 'desc'),
    limit(limitTo)
  );
  const querySnap = await getDocs(q);
  return querySnap.docs.map((doc) => doc.data());
};

export const getLogsByUser = async (userID, limitTo = 10) => {
  if (!userID) return false;
  const q = query(
    activityCollection,
    where('user', '==', userID),
    orderBy('ts', 'desc'),
    limit(limitTo)
  );
  console.log(q);
  const querySnap = await getDocs(q);
  console.log(querySnap);
  return querySnap.docs.map((doc) => doc.data());
};
