<template>
  <div class="layout-wrapper layout-1">
    <div class="layout-inner">
      <b-navbar
        toggleable="lg"
        class="layout-navbar bg-navbar-theme align-items-lg-center container-p-x"
      >
        <!-- Brand demo (see demo.css) -->
        <b-navbar-brand
          :to="{ name: 'GuestDashboard' }"
          class="app-brand demo py-0 mr-4"
        >
          <!-- <span class="app-brand-text demo font-weight-normal ml-2">
            Divcon Controls
          </span> -->
          <span class="app-brand-logo guest">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 181 36">
              <g fill-rule="nonzero" fill="none">
                <path
                  class="div"
                  d="M11.488 35c2.464 0 4.696-.408 6.696-1.224 2-.816 3.704-1.968 5.112-3.456s2.488-3.28 3.24-5.376c.752-2.096 1.128-4.44 1.128-7.032 0-2.336-.336-4.536-1.008-6.6-.672-2.064-1.68-3.864-3.024-5.4-1.344-1.536-3.032-2.752-5.064-3.648C16.536 1.368 14.176.92 11.488.92H.16V35h11.328Zm0-2.208h-8.88V3.128h8.88c2.208 0 4.16.368 5.856 1.104 1.696.736 3.128 1.76 4.296 3.072 1.168 1.312 2.056 2.872 2.664 4.68.608 1.808.912 3.784.912 5.928 0 2.112-.296 4.072-.888 5.88-.592 1.808-1.464 3.384-2.616 4.728-1.152 1.344-2.584 2.392-4.296 3.144-1.712.752-3.688 1.128-5.928 1.128ZM38.832 35V.92h-2.448V35h2.448Zm23.888 0L77.12.92h-2.592l-12.96 31.008L48.608.92h-2.592L60.464 35h2.256Z"
                  fill="#F4F5F5"
                />
                <path
                  class="pro"
                  d="M90.064 35V23.576h8.064c1.568 0 3.008-.32 4.32-.96a10.612 10.612 0 0 0 3.36-2.544 11.661 11.661 0 0 0 2.16-3.624c.512-1.36.768-2.76.768-4.2 0-1.376-.272-2.744-.816-4.104a12.231 12.231 0 0 0-2.256-3.648 11.238 11.238 0 0 0-3.432-2.592c-1.328-.656-2.776-.984-4.344-.984H83.44V35h6.624Zm7.68-17.232h-7.68V6.728h7.392c.576 0 1.144.12 1.704.36s1.048.6 1.464 1.08c.416.48.752 1.064 1.008 1.752s.384 1.464.384 2.328c0 1.696-.408 3.04-1.224 4.032-.816.992-1.832 1.488-3.048 1.488ZM121.968 35V23.576h6.528L135.456 35h7.44l-7.776-12.816c1.888-.8 3.384-2.104 4.488-3.912 1.104-1.808 1.656-3.816 1.656-6.024 0-1.376-.272-2.744-.816-4.104a12.231 12.231 0 0 0-2.256-3.648 11.238 11.238 0 0 0-3.432-2.592c-1.328-.656-2.776-.984-4.344-.984h-15.072V35h6.624Zm8.304-17.232h-8.304V6.728h8.016c.576 0 1.144.136 1.704.408.56.272 1.056.656 1.488 1.152.432.496.776 1.08 1.032 1.752.256.672.384 1.408.384 2.208 0 .768-.112 1.488-.336 2.16a5.863 5.863 0 0 1-.912 1.752c-.384.496-.84.888-1.368 1.176a3.503 3.503 0 0 1-1.704.432Zm33.536 17.472c2.4 0 4.624-.472 6.672-1.416s3.808-2.208 5.28-3.792a17.658 17.658 0 0 0 3.456-5.472 17.234 17.234 0 0 0 1.248-6.504c0-2.144-.4-4.256-1.2-6.336-.8-2.08-1.92-3.936-3.36-5.568-1.44-1.632-3.176-2.952-5.208-3.96S166.416.68 163.952.68c-2.4 0-4.624.472-6.672 1.416s-3.816 2.216-5.304 3.816a18.015 18.015 0 0 0-3.504 5.52 17.128 17.128 0 0 0-1.272 6.528c0 2.176.4 4.304 1.2 6.384a17.69 17.69 0 0 0 3.384 5.544c1.456 1.616 3.2 2.912 5.232 3.888s4.296 1.464 6.792 1.464Zm.048-5.856c-1.568 0-2.968-.32-4.2-.96a9.666 9.666 0 0 1-3.12-2.544c-.848-1.056-1.496-2.272-1.944-3.648a13.712 13.712 0 0 1-.672-4.272c0-1.408.216-2.792.648-4.152a11.641 11.641 0 0 1 1.896-3.648 9.47 9.47 0 0 1 3.12-2.592c1.248-.656 2.672-.984 4.272-.984 1.536 0 2.92.312 4.152.936a9.395 9.395 0 0 1 3.12 2.52c.848 1.056 1.504 2.272 1.968 3.648.464 1.376.696 2.8.696 4.272 0 1.408-.216 2.792-.648 4.152a11.662 11.662 0 0 1-1.92 3.672 9.673 9.673 0 0 1-3.12 2.616c-1.232.656-2.648.984-4.248.984Z"
                  fill="#B8242A"
                />
              </g>
            </svg>
          </span>
        </b-navbar-brand>

        <b-navbar-nav
          v-if="authenticated && user.profile"
          class="align-items-lg-center ml-auto"
        >
          <b-nav-item-dropdown right class="navbar-user">
            <template slot="button-content">
              <span
                class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
              >
                <b-avatar
                  variant="default"
                  rounded
                  :src="user.profile.photoURL"
                  :text="initials"
                />
              </span>
            </template>
            <b-dd-item @click="signOut()">
              <i class="ion ion-ios-log-out text-danger mr" />Log Out
            </b-dd-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-navbar>

      <div class="layout-container">
        <!-- <app-layout-sidenav /> -->

        <div class="layout-content">
          <div
            class="router-transitions container-fluid flex-grow-1 container-p-y"
          >
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import LayoutNavbar from './LayoutNavbar'
// import LayoutSidenav from './LayoutSidenav'
// import LayoutFooter from './LayoutFooter'

export default {
  name: 'GuestLayout',

  computed: {
    ...mapGetters({
      authenticated: 'auth/IS_AUTHENTICATED',
      user: 'users/user',
    }),
    initials() {
      if (!this.user || !this.user.profile || !this.user.profile.displayName) {
        return '';
      }
      const [first, last] = this.user.profile.displayName.split(' ');
      return `${first.charAt(0)}${last.charAt(0)}`;
    },
  },

  mounted() {
    this.layoutHelpers.init();
    this.layoutHelpers.update();
    this.layoutHelpers.setAutoUpdate(true);
  },

  beforeDestroy() {
    this.layoutHelpers.destroy();
  },

  methods: {
    ...mapActions('auth', {
      signOut: 'SIGN_OUT',
    }),

    closeSidenav() {
      this.layoutHelpers.setCollapsed(true);
    },
  },
};
</script>

<style lang="scss">
.app-brand-logo.guest {
  margin: 10px 0;
  svg {
    height: 20px !important;
    margin-left: auto;
    width: auto;
    // margin-left: -46px;
    // transition: margin-left 0.2s;
    .div {
      display: block;
      fill: #666;
    }
  }
}
</style>
