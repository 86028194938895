import Vue from 'vue';
import { ticketCollection, fstore } from '@/services/firebase/db';

import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  onSnapshot,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';

import { checkUID20 } from '@/utilities';

const state = {
  ticketsLoading: true,
  ticketLoading: true,
  ticketID: null,
  ticketData: {},
  tickets: [],
  error: null,
};

// global scoped listener var so that we can detatch later
let docListener = null;

const getters = {
  GET_TICKETS: (state) => state.tickets,
  GET_TICKET: (state) => state.ticketData,
  TICKETS_LOADING: (state) => state.ticketsLoading,
  TICKET_LOADING: (state) => state.ticketLoading,
};

const actions = {
  async GET_TICKETS({ commit }) {
    try {
      commit('SET_TICKETSLOADING', true);
      const q = query(ticketCollection, orderBy('id'));
      const docSnap = await getDocs(q);
      const tickets = docSnap.docs.map((doc) => doc.data());
      commit('SET_TICKETS', tickets);
      commit('SET_TICKETSLOADING', false);
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  async QUERY_TICKETS({ commit }, queryParams) {
    try {
      commit('SET_TICKETSLOADING', true);
      const params = [];
      for (const [key, value] of Object.entries(queryParams)) {
        if (value !== null) {
          params.push(where(key, '==', value));
        }
      }
      params.push(orderBy('id'));
      const q = query(ticketCollection, ...params);
      const docSnap = await getDocs(q);
      const tickets = docSnap.docs.map((doc) => doc.data());
      commit('SET_TICKETS', tickets);
      commit('SET_TICKETSLOADING', false);
    } catch (error) {
      console.log(error);
      commit('SET_ERROR', error);
    }
  },

  async GET_TICKET({ state, commit }, docID) {
    commit('SET_TICKETLOADING', true);
    if (!checkUID20(docID)) {
      return commit('SET_ERROR', new Error('Missing UID'));
    }
    // No Need to fetch data, doc listener is available
    if (docListener && state.ticketID === docID) return;
    // kill old listener before attaching new one
    if (docListener) {
      docListener();
    }
    commit('SET_TICKET_UID', docID);
    try {
      const docRef = doc(ticketCollection, docID);
      docListener = onSnapshot(docRef, async (doc) => {
        const data = doc.data();
        const q = query(collection(fstore, 'tickets', doc.id, 'replies'));
        const docSnap = await getDocs(q);
        data.replies = docSnap.docs.map((doc) => doc.data());
        commit('SET_TICKET_DATA', data);
        commit('SET_TICKETLOADING', false);
      });
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  async UPDATE_TICKET({ commit }, { docID, update }) {
    if (!checkUID20(docID)) {
      return commit('SET_ERROR', new Error('Missing UID'));
    }
    try {
      const docRef = doc(ticketCollection, docID);
      commit('SET_TICKETSLOADING', true);
      await updateDoc(docRef, update);
      const docSnap = await getDoc(docRef);
      commit('SET_TICKET_UPDATE', docSnap.data());
      commit('SET_TICKETSLOADING', false);
    } catch (error) {
      commit('SET_ERROR', new Error(error));
    }
  },

  async DELETE_TICKET({ commit }, docID) {
    if (!checkUID20(docID)) {
      return commit('SET_ERROR', new Error('Missing UID'));
    }
    try {
      const docRef = doc(ticketCollection, docID);
      await deleteDoc(docRef);
    } catch (error) {
      commit('SET_ERROR', new Error(error));
    }
  },

  async CLEAR_TICKET_DATA({ commit }) {
    try {
      commit('CLEAR_TICKET_DATA');
    } catch (error) {
      commit('SET_ERROR', new Error(error));
    }
  },
};

const mutations = {
  SET_TICKETSLOADING: (state, loading) => {
    state.ticketsLoading = loading;
  },
  SET_TICKETLOADING: (state, loading) => {
    state.ticketLoading = loading;
  },
  SET_TICKETS: (state, tickets) => {
    state.tickets = tickets;
  },
  SET_TICKET_UID: (state, uid) => {
    state.ticketID = uid;
  },
  SET_TICKET_DATA: (state, data) => {
    state.ticketData = data;
  },
  SET_TICKET_UPDATE: (state, data) => {
    const index = state.tickets.map((t) => t.uid).indexOf(data.uid);
    Vue.set(state.tickets, index, data);
    // state.tickets[index] = data
  },
  CLEAR_TICKET_DATA: (state) => {
    if (docListener) docListener();
    state.ticketID = null;
    state.ticketData = {};
  },
  SET_ERROR: (state, error) => {
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
