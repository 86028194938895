import LayoutBlank from '@/layout/LayoutBlank';

const routes = [
  {
    path: '/auth',
    component: LayoutBlank,
    children: [
      {
        name: 'Login',
        path: 'login',
        component: () => import('@/views/auth/Login'),
      },
      {
        path: 'register',
        component: () => import('@/views/auth/Register'),
      },
      {
        path: 'password-reset',
        component: () => import('@/views/auth/ResetPassword'),
      },
    ],
  },
];

export default routes;
