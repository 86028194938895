// modalStore
const state = {
  userSelect: {
    visible: false,
    title: null,
    size: null,
    filter: null,
    selected: null,
    data: null,
  },
};

const getters = {
  userSelectModal: (state) => state.userSelect,
};

const actions = {
  async SHOW_MODAL({ commit }, { modal, data }) {
    commit('setModalVisibility', { modal, visible: true, data });
  },
  async CLOSE_MODAL({ commit }, { modal, data }) {
    commit('setModalVisibility', { modal, visible: false, data });
  },
};

const mutations = {
  setModalVisibility: (state, { modal, visible = false, data = null }) => {
    console.log(`TOGGLE MODAL ${modal} - ${visible}`);
    state[modal] = {
      ...state[modal],
      visible,
      data,
      filter: data ? data.filter : null,
      title: data ? data.label : null,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
