<template>
  <!-- <b-form-select v-model="select" :options="options" @change="$emit('update:selected', select);" v-if="options.length">
    </b-form-select> -->
  <v-select
    v-if="options.length"
    v-model="select"
    class=""
    :options="options"
    :reduce="(user) => user.value"
    :clearable="false"
    select-on-tab
    :taggable="taggable"
    @option:selected="$emit('update:selected', select)"
    @option:created="newCustomer"
  >
    <template #open-indicator="{ attributes }">
      <span v-bind="attributes" class="ion ion-md-arrow-dropdown px-2" />
    </template>
  </v-select>
</template>

<script>
import VSelect from 'vue-select';
import { mapGetters, mapActions } from 'vuex';
import { customerCollection } from '@/services/firebase/db';
import { doc } from 'firebase/firestore';

export default {
  name: 'CustomerSelect',
  components: {
    VSelect,
  },
  props: {
    taggable: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      select: null,
    };
  },
  computed: {
    ...mapGetters('customers', {
      customers: 'customers',
    }),
    options() {
      if (!this.customers) {
        return [];
      }
      const customers = [...this.customers].map((customer) => {
        return { value: customer.uid, label: customer.displayName };
      });

      return [
        { value: null, label: 'Please Select A Customer', disabled: false },
        ...customers,
      ];
    },
  },

  async created() {
    // this.getCustomers()
    this.select = this.selected;
  },

  methods: {
    ...mapActions('customers', {
      getCustomers: 'GET_CUSTOMERS',
      addCustomer: 'ADD_CUSTOMER',
    }),
    async newCustomer(event) {
      const { id: uid } = doc(customerCollection);
      const { label: displayName } = event;
      await this.addCustomer({
        uid,
        displayName,
      });
      this.select = uid;
      this.$emit('update:selected', uid);
    },
  },
};
</script>

<style lang="scss">
// .vs__selected {
//   line-height: 1.8;
// }
</style>
