import AppLayout from '@/layout/AppLayout';

const routes = [
  {
    path: '/app/helpdesk',
    component: AppLayout,
    children: [
      {
        name: 'helpDesk',
        path: '',
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/views/materials/BillOfMaterialsReview'),
      },
    ],
  },
];

export default routes;
