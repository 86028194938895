import {
  projectCollection,
  taskCompletionCollection,
  notesCollection,
} from '@/services/firebase/db';

import { isEqual } from 'lodash';

import {
  doc,
  query,
  where,
  and,
  or,
  onSnapshot,
  orderBy,
  limit,
  getDocs,
  setDoc,
  updateDoc,
  deleteDoc,
  arrayUnion,
  arrayRemove,
  serverTimestamp,
} from 'firebase/firestore';

import { checkUID20, checkUID28, parseSortID } from '@/utilities';

const state = {
  projectInfo: {},

  // individual project data
  projectIsLoading: true,
  currentProject: null,
  projectData: {},
  projectTasks: [],
  projectSystems: [],

  // query data
  sidebarIsVisible: false,
  query: {},
  queryResults: [],
  queryIsLoading: true,

  watchedProjects: [],
};

// global scoped listener var so that we can detatch later
let projectListener = null;
let queryListener = null;
let taskListener = null;

const getters = {
  PROJECTS_LOADING: (state) => state.isLoading,
  sidebarIsVisible: (state) => state.sidebarIsVisible,
  getProjects: (state) => Object.values(state.projectInfo),
  query: (state) => state.query,
  queryResultLength: (state) => state.queryResults.length,
  queryResults: (state) => state.queryResults,
  GET_WATCHED_PROJECTS: (state) => state.watchedProjects,
  GET_PROJECT: (state) => state.projectData,
  getProjectId: (state) => state.currentProject,
  billing: (state) => {
    return state.projectData.billing !== undefined
      ? state.projectData.billing
      : {
          graphics_percent: 0,
          materials_percent: 0,
          installation_percent: 0,
          panelFabrication_percent: 0,
          projectManagement_percent: 0,
          commissioning_percent: 0,
          controllerDatabases_percent: 0,
          engineeringSubmittals_percent: 0,
          closeOutDocuments_percent: 0,
          retainage: false,
        };
  },
  GET_PROJECT_SYSTEMS: (state) => state.projectSystems,
  GET_TASK_PROGRESS: (state) => state.projectTasks,
  // third param rootState destructured to users.user
  GET_WATCHER_STATUS: (state, getters, { users: { user } }) => {
    const userId = user.uid || '';
    if (!state.projectData.watchers) return false;
    return state.projectData.watchers.includes(userId);
  },
  projectInfo: (state) => (uid) => {
    if (!uid || uid.length !== 20) {
      // UID is not available or UID is not a valid firestore uid
      return {
        name: 'Invalid UID',
        uid,
      };
    } else if (!state.projectInfo[uid]) {
      return {
        name: 'Unable to load Project Data',
        uid,
      };
    }
    return state.projectInfo[uid];
  },
  IS_LOADING: (state) => state.projectIsLoading,
};

const actions = {
  async GET_PROJECTS({ commit, dispatch }) {
    try {
      const q = query(
        projectCollection,
        // where('completed', '==', false),
        orderBy('id', 'asc')
      );
      const querySnap = await getDocs(q);
      const projects = querySnap.docs.map((doc) => doc.data()) || [];
      commit('SET_PROJECTS', projects);
    } catch (error) {
      dispatch('app/ADD_ERROR', error, {
        root: true,
      });
    }
  },

  async GET_WATCHED_PROJECTS({ commit, dispatch, rootGetters }, userID) {
    if (!userID) {
      // default to logged in users task list
      userID = rootGetters['users/GET_USER_ID'];
    }
    //  if no userID exit function
    if (userID === undefined) return;

    // Query projects that are both watched & active
    try {
      const q = query(
        projectCollection,
        where('completed', '==', false),
        where('watchers', 'array-contains', userID),
        orderBy('updated', 'desc')
      );
      const querySnap = await getDocs(q);
      const projects = querySnap.docs.map((doc) => doc.data());
      commit('SET_WATCHED_PROJECTS', projects);
    } catch (error) {
      dispatch('app/ADD_ERROR', error, {
        root: true,
      });
    }
  },

  async QUERY_PROJECTS(
    { commit, getters, dispatch, rootGetters },
    queryParams
  ) {
    // check for query change before listener change
    if (queryListener && isEqual(getters.query, queryParams)) return;
    // set updated queryParams to state
    commit('setQuery', queryParams);
    commit('setLoading', true);
    // detatch listener
    if (queryListener) {
      queryListener();
    }

    // build query
    const q = [];
    for (const [key, value] of Object.entries(queryParams)) {
      if (value !== null || typeof value !== 'undefined') {
        // if value is an array, use array-contains-any

        switch (key) {
          case 'watchers': {
            const userID = rootGetters['users/GET_USER_ID'];
            q.push(where(key, 'array-contains', userID));
            break;
          }
          case 'lead': {
            q.push(
              or(
                where('contacts.pm', '==', value),
                where('contacts.lead', '==', value)
              )
            );
            break;
          }
          case 'engineer': {
            q.push(
              or(
                where('contacts.databases', '==', value),
                where('contacts.drawings', '==', value),
                where('contacts.graphics', '==', value)
              )
            );
            break;
          }
          default:
            q.push(where(key, '==', value));
            break;
        }
      }
    }

    console.log(q);

    // wrap query in and statement if includes multiple where statements;
    let queryStatement = q.length > 1 ? and(...q) : q[0];
    console.log(queryStatement);

    try {
      commit('clearQueryResults');
      queryListener = onSnapshot(
        query(projectCollection, queryStatement, orderBy('sortID', 'desc')),
        (querySnap) => {
          commit('setQueryResults', querySnap);
          commit('setLoading', false);
        }
      );
      // const querySnap = await getDocs()
      // const projects = querySnap.docs.map(doc => doc.data()) || []
      // commit('setQueryResults', projects)
    } catch (error) {
      console.log(error);
      dispatch('app/ADD_ERROR', error, {
        root: true,
      });
    }
  },

  async GET_PROJECT({ state, commit, dispatch }, projectID) {
    // projectID is a UUID from firebase
    if (checkUID20(projectID)) {
      // No Need to attach a second listener. Project data is already loaded

      if (state.currentProject === projectID) return;

      // Set Loading State for transition
      commit('setLoading', true);
      // Clean project state and detatch any existing listeners
      dispatch('CLEAR_PROJECT');

      try {
        // Attach the current project doc listener
        const ref = doc(projectCollection, projectID);
        projectListener = onSnapshot(ref, (snap) => {
          commit('SET_PROJECT_DATA', snap.data());
          commit('setLoading', false);
        });
      } catch (error) {
        dispatch('app/ADD_ERROR', error, {
          root: true,
        });
      }

      // grab associated project info
      // dispatch('GET_PROJECT_SYSTEMS', projectID)
      dispatch('GET_PROJECT_TASK_PROGRESS', projectID);
      dispatch('systems/GET_SYSTEMS', projectID, {
        root: true,
      });
      dispatch('punchlist/GET_PUNCHLIST_ITEMS', projectID, {
        root: true,
      });
      // Set UUID after dispatching actions
      commit('SET_PROJECT_UID', projectID);
      // this is for later (update routes to use divcon projectId)
    } else if (projectID && projectID !== state.projectData.projectId) {
      // projectID is DivconID '18-2045'
      try {
        const q = query(
          projectCollection,
          where('projectId', '==', projectID),
          limit(1)
        );
        const querySnap = await getDocs(q);
        // const query = await projectCollection.where('projectId', '==', projectID).limit(1).get()
        for (const project of querySnap.docs) {
          // TODO: Handle error -- cant find DivconID
          if (!project.exists) throw new Error('error');
          commit('SET_PROJECT_DATA', project.data());
        }
      } catch (error) {
        dispatch('app/ADD_ERROR', error, {
          root: true,
        });
      }
    } else {
      // TODO: Clean Up error handling
      dispatch('app/ADD_ERROR', new Error('Missing Project ID'), {
        root: true,
      });
    }
  },

  async GET_PROJECT_TASK_PROGRESS({ state, commit, dispatch }, projectID) {
    // confirm that the uid exists and is a firebase id
    if (!checkUID20(projectID)) {
      commit('SET_ERROR', 'Firebase UUID Required for Task Progress');
    }

    // exit function if listener is already active
    if (taskListener && state.currentProject === projectID) return;
    // projectID is new, detach listener
    if (taskListener) taskListener();

    try {
      const q = query(taskCompletionCollection(projectID), orderBy('order'));
      taskListener = onSnapshot(q, (querySnap) => {
        commit('SET_TASK_PROGRESS', querySnap);
      });
    } catch (error) {
      dispatch('app/ADD_ERROR', error, {
        root: true,
      });
    }
  },

  async WATCH_PROJECT({ state, dispatch, rootGetters }, userID) {
    if (!checkUID28(userID)) {
      userID = rootGetters['users/GET_USER_ID'];
    }
    console.log('action', userID);
    const docRef = doc(projectCollection, state.currentProject);
    try {
      await updateDoc(docRef, {
        watchers: arrayUnion(userID),
      });
    } catch (error) {
      dispatch('app/ADD_ERROR', error, {
        root: true,
      });
    }
  },

  // used to add recent notes on project reports
  async TOGGLE_DETAILS({ commit, state }, item) {
    const { uid, notes, _showDetails } = item;
    if (!uid || !checkUID20(uid)) return;

    const index = state.queryResults.findIndex((o) => o.uid === uid);

    // only pass the closed showdetails prop back to state
    item._showDetails = !item._showDetails;
    if (notes && _showDetails) {
      return commit('SET_TOGGLE_DETAILS', {
        index,
        item,
      });
    }

    // query additional data and add to the project array
    const q = query(notesCollection(uid), orderBy('ts', 'desc'));
    const querySnap = await getDocs(q);
    item.notes = querySnap.docs.map((doc) => doc.data());
    commit('SET_TOGGLE_DETAILS', {
      index,
      item,
    });
  },

  async UNWATCH_PROJECT({ state, dispatch, rootGetters }, userID) {
    if (!userID || !checkUID28(userID)) {
      userID = rootGetters['users/GET_USER_ID'];
    }
    const docRef = doc(projectCollection, state.currentProject);
    try {
      await updateDoc(docRef, {
        watchers: arrayRemove(userID),
      });
    } catch (error) {
      dispatch('app/ADD_ERROR', error, {
        root: true,
      });
    }
  },

  async SET_PROJECT_MANAGER({ state, dispatch }, userID) {
    if (!userID || !checkUID28(userID)) return;
    const docRef = doc(projectCollection, state.currentProject);
    try {
      await updateDoc(docRef, { pm: userID });
      dispatch('WATCH_PROJECT', userID);
    } catch (error) {
      dispatch('app/ADD_ERROR', error, {
        root: true,
      });
    }
  },

  async CREATE_PROJECT({ commit, dispatch, rootGetters }, project) {
    if (!project) {
      // TODO: Clean Up error handling
      commit('SET_ERROR', new Error('Missing Project'));
      return null;
    }
    try {
      const userId = rootGetters['users/GET_USER_ID'];
      const docRef = doc(projectCollection);
      project.sortID = parseSortID(project.id);
      project.created = serverTimestamp();
      project.createdBy = userId;
      project.uid = docRef.id;
      const { contacts } = project;
      if (contacts && contacts.pm && checkUID28(contacts.pm)) {
        // set pm as original watcher
        project.watchers = [contacts.pm];
      }
      await setDoc(docRef, project);
      dispatch('GET_PROJECTS');
    } catch (error) {
      dispatch('app/ADD_ERROR', error, {
        root: true,
      });
    }
  },

  async DELETE_PROJECT({ commit, dispatch }, projectID) {
    console.log('ran', projectID);
    if (!projectID || !checkUID20(projectID)) {
      commit('SET_ERROR', 'Firebase UUID Required');
    }
    try {
      // TODO: Need to run a cron job to delete subcollections of projects
      const ref = doc(projectCollection, projectID);
      await deleteDoc(ref);
      dispatch('GET_PROJECTS');
    } catch (error) {
      dispatch('app/ADD_ERROR', error, {
        root: true,
      });
    }
  },

  async UPDATE_PROJECT({ commit, dispatch }, { projectID, update }) {
    if (!projectID || !checkUID20(projectID)) {
      commit('SET_ERROR', 'Firebase UUID Required');
    }
    console.log(projectID, update);
    try {
      const ref = doc(projectCollection, projectID);
      update.updated = serverTimestamp();
      await updateDoc(ref, update);
      dispatch('GET_PROJECTS');
    } catch (error) {
      dispatch('app/ADD_ERROR', error, {
        root: true,
      });
    }
  },

  async CLEAR_PROJECT({ dispatch, commit }) {
    console.debug('👂 Cleared Listeners');
    if (projectListener) projectListener();
    if (taskListener) taskListener();
    commit('CLEAR_PROJECT_DATA');
    dispatch('punchlist/CLEAR_ITEMS', {}, { root: true });
    dispatch('notes/CLEAR_NOTES', {}, { root: true });
    dispatch('systems/CLEAR_SYSTEMS', {}, { root: true });

    // if (systemListener) systemListener()
  },
};

const mutations = {
  SET_PROJECTS: (state, projects) => {
    // state.projects = projects
    projects.forEach((project) => {
      const { uid, id, name, pm, completed } = project;
      state.projectInfo[project.uid] = Object.freeze({
        uid,
        id,
        name,
        pm,
        completed,
      });
    });
  },

  setQuery: (state, query) => {
    state.query = query;
  },

  setQueryResults: (state, querySnap) => {
    const changes = querySnap.docChanges();
    changes.forEach((change) => {
      if (change.oldIndex !== -1) {
        state.queryResults.splice(change.oldIndex, 1);
      }
      if (change.newIndex !== -1) {
        state.queryResults.splice(
          change.newIndex,
          0,
          Object.freeze(change.doc.data())
        );
      }
    });
  },

  SET_WATCHED_PROJECTS: (state, projects) => {
    state.watchedProjects = projects;
  },

  SET_PROJECT_SYSTEMS: (state, querySnap) => {
    const changes = querySnap.docChanges();
    changes.forEach((change) => {
      if (change.oldIndex !== -1) {
        state.projectSystems.splice(change.oldIndex, 1);
      }
      if (change.newIndex !== -1) {
        state.projectSystems.splice(
          change.newIndex,
          0,
          Object.freeze(change.doc.data())
        );
      }
    });
  },

  toggleDetails: (state, item) => {
    const index = state.queryResults.findIndex((o) => o.uid === item.uid);
    item._showDetails = !item._showDetails;
    state.queryResults.splice(index, 1, item);
  },

  SET_PROJECT_UID: (state, uid) => {
    state.currentProject = uid;
  },
  SET_PROJECT_DATA: (state, projectData) => {
    state.projectData = projectData;
  },
  SET_TASK_PROGRESS: (state, querySnap) => {
    const changes = querySnap.docChanges();
    changes.forEach((change) => {
      if (change.oldIndex !== -1) {
        state.projectTasks.splice(change.oldIndex, 1);
      }
      if (change.newIndex !== -1) {
        state.projectTasks.splice(
          change.newIndex,
          0,
          Object.freeze(change.doc.data())
        );
      }
    });
  },
  CLEAR_PROJECT_DATA: (state) => {
    state.currentProject = null;
    state.projectData = {};
    state.projectTasks = [];
    state.projectSystems = [];
  },
  clearQueryResults: (state) => {
    state.queryResults = [];
  },
  setLoading: (state, loading) => {
    state.projectIsLoading = loading;
  },
  toggleSidebarVisible: (state, visible) => {
    state.sidebarIsVisible = visible;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
