import { doc, setDoc, query, orderBy, getDocs } from 'firebase/firestore';

import { customerCollection } from '@/services/firebase/db';

import { checkUID20 } from '@/utilities';

// customerStore
const state = {
  customers: [],
};

const getters = {
  customerInfo: (state) => (uid) => {
    if (!uid || !checkUID20(uid)) {
      return {
        // UID is not available or UID is not a valid firestore uid
        displayName: 'Invalid UID',
        uid,
      };
    }
    return state.customers.find((u) => u.uid === uid) || {};
  },
  customers: (state) => state.customers.filter((u) => u.active) || [],
  archivedCustomers: (state) => state.customers.filter((u) => !u.active),
};

const actions = {
  async GET_CUSTOMERS({ commit }) {
    try {
      const q = query(customerCollection, orderBy('displayName'));
      const querySnap = await getDocs(q);
      const customers = [];

      for (const customer of querySnap.docs) {
        customers.push(customer.data());
      }
      // commit comment collection
      commit('SET_CUSTOMERS', customers);
    } catch (error) {
      console.log(error);
      commit('SET_ERROR', error);
    }
  },

  async ADD_CUSTOMER({ dispatch }, customer) {
    try {
      if (!customer.uid || customer.uid.length !== 20) {
        return;
      }
      const docRef = doc(customerCollection, customer.uid);
      await setDoc(docRef, {
        ...customer,
        active: true,
        shortName: customer.displayName,
      });
      dispatch('GET_CUSTOMERS');
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  SET_CUSTOMERS: (state, customers) => {
    state.customers = customers.map((customer) => Object.freeze(customer));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
