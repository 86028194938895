import { notesCollection, projectCollection } from '@/services/firebase/db';
import {
  deleteDoc,
  doc,
  orderBy,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from '@firebase/firestore';
import { logger } from '@/services/log';
import { checkUID20 } from '@/utilities';

// userStore
const state = {
  notes: [],
  note: null,
  error: null,
};

const getters = {
  GET_NOTES: (state) => state.notes,
  GET_NOTE: (state) => state.note,
};

let queryListener = null;

const actions = {
  async QUERY_NOTES({ commit }, { projectID, bomID, type }) {
    console.log(projectID, bomID, type);
    // Check that projectID is a UUID from firebase
    if (!projectID || !checkUID20(projectID))
      return commit('SET_ERROR', 'Firebase UUID Required');
    // projectID is new, detach listener
    if (queryListener) queryListener();
    commit('CLEAR_NOTES');
    // build params for query
    const params = [];
    // BOM specific notes
    if (bomID) params.push(where('bomID', '==', bomID));
    // public notes
    if (type === 'external') params.push(where('external', '==', true));
    console.log(type, params);
    params.push(orderBy('ts', 'desc'));
    try {
      const q = query(notesCollection(projectID), ...params);
      queryListener = onSnapshot(q, (querySnap) => {
        commit('SET_NOTES', querySnap);
      });
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  async CREATE_NOTE({ commit, rootGetters }, note) {
    if (!note) return;

    // Check for Firestore ID and that ID !== Project number
    if (!note.projectID || !checkUID20(note.projectID)) {
      commit('SET_ERROR', 'Firebase UUID Required');
      return;
    }

    try {
      note.ts = serverTimestamp();
      note.createdBy = rootGetters['users/GET_USER_ID'];
      const docRef = doc(notesCollection(note.projectID));
      await setDoc(docRef, note);
      const projectRef = doc(projectCollection, note.projectID);
      await updateDoc(projectRef, {
        updated: serverTimestamp(),
      });
      logger({
        type: 'addNote',
        projectID: note.projectID,
        note: note.note,
      });
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  async TOGGLE_EDIT_NOTE({ commit, state }, note) {
    if (!note) return;
    const index = state.notes.findIndex((n) => n === note);
    commit('SET_EDITABLE', index);
  },

  async UPDATE_NOTE({ commit, rootGetters }, { note, update, external }) {
    console.log('IN UPDATE_NOTE');
    if (!note) return;
    // Check for Firestore ID and that ID !== Project number
    if (!note.projectID || !checkUID20(note.projectID)) {
      commit('SET_ERROR', 'Firebase UUID Required');
      return;
    }

    try {
      const { projectID, uid } = note;
      const userID = rootGetters['users/GET_USER_ID'];
      const docRef = doc(notesCollection(projectID), uid);
      const newNote = { ...note };
      delete newNote.editable;
      if (update) {
        newNote.updated = serverTimestamp();
        newNote.updatedBy = userID;
        newNote.note = update || note.note;
      }
      newNote.external =
        typeof external !== 'undefined' ? external : note.external;

      await updateDoc(docRef, newNote);
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  async DELETE_NOTE({ commit }, note) {
    // Check that data exists and Firestore ID and that ID !== Project number
    if (!note || !checkUID20(note.projectID) || !note.uid) {
      commit('SET_ERROR', 'Firebase UUID Required for Punchlist');
    }

    try {
      const { projectID, uid } = note;
      const docRef = doc(notesCollection(projectID), uid);
      await deleteDoc(docRef);
      // await projectCollection.doc(projectID).collection('punchList').doc(uid).delete()
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  async CLEAR_NOTES({ commit }) {
    if (queryListener) queryListener();
    commit('CLEAR_NOTES');
  },
};

const mutations = {
  SET_NOTES: (state, querySnap) => {
    const changes = querySnap.docChanges();
    changes.forEach((change) => {
      if (change.oldIndex !== -1) {
        state.notes.splice(change.oldIndex, 1);
      }
      if (change.newIndex !== -1) {
        const doc = change.doc.data();
        doc.uid = change.doc.id;
        // state.notes.splice(change.newIndex, 0, Object.freeze(doc))
        state.notes.splice(change.newIndex, 0, doc);
      }
    });
  },
  SET_EDITABLE: (state, index) => {
    const note = state.notes[index];
    note.editable = !note.editable;
    state.notes.splice(index, 1, note);
  },
  CLEAR_NOTES: (state) => {
    state.notes = [];
  },
  SET_ERROR: (state, error) => {
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
