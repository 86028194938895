import { getDoc, doc, updateDoc, increment } from '@firebase/firestore';
import { nanoid } from 'nanoid';

import { metadataCollection } from '../firebase/db';

export const uuid = () => nanoid(8);

export const engTicketId = async () => {
  const docRef = doc(metadataCollection, 'tickets');
  const snapshot = await getDoc(docRef);
  return snapshot.data().engineering.id;
};

export const newEngTicket = async () => {
  const docRef = doc(metadataCollection, 'tickets');
  return updateDoc(docRef, {
    'engineering.id': increment(1),
  });
};
