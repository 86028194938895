<template>
  <label class="nav-item navbar-text navbar-search-box p-0 d-none d-md-flex">
    <i class="ion ion-ios-search navbar-icon align-middle" />
    <!-- <div id="search" class="navbar-search-input pl-2" /> -->
    <span class="navbar-search-input pl-2">
      <vue-typeahead-bootstrap
        id="searchbar"
        v-model="query"
        background-variant="primary"
        input-class="form-control navbar-text mx-2"
        disable-sort
        :serializer="(item) => `${item.id} - ${item.customerID} - ${item.name}`"
        :data="results"
        @input="debouncedSearch"
        @hit="selected"
      />
    </span>
  </label>
</template>

<script>
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import algoliasearch from 'algoliasearch/lite';
export default {
  name: 'SearchBar',
  components: {
    VueTypeaheadBootstrap,
  },
  data() {
    return {
      results: [],
      query: null,
      index: null,
      debouncedSearch: null,
    };
  },
  mounted() {
    const client = algoliasearch(
      'UML90ZQ15Z',
      'e6745c0231c3881371cf921a2fe9d033'
    );
    this.index = client.initIndex('projects');
    this.debouncedSearch = debounce(this.search, 250);
  },

  async created() {
    this.select = this.selected;
  },

  methods: {
    async search(query) {
      // console.log(query)
      const results = await this.index.search(query, {
        hitsPerPage: 10,
        removeWordsIfNoResults: 'allOptional',
      });
      this.results = results.hits;
    },
    selected(evt) {
      const { projectId } = this.$route.params;
      if (projectId && projectId === evt.objectID) {
        return;
      }
      this.$router.push({
        name: 'ProjectDetail',
        params: { projectId: evt.objectID },
      });
    },
  },
};

function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    const args = arguments;
    const that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}
</script>

<style lang="scss">
#searchbar {
  width: 50vw;
}
.vbt-autcomplete-list {
  .list-group-item {
    background-color: white !important;
  }
}
.navbar-search-input {
  max-width: 500px !important;
}
</style>
