import { collection, onSnapshot } from 'firebase/firestore';

import { fstore } from '@/services/firebase/db';

// appStore
const state = {
  metadata: {},
  error: null,
};

let docListener = null;

const getters = {
  metadata: (state) => state.metadata,
  error: (state) => state.error,
};

const actions = {
  async INIT({ dispatch }) {
    dispatch('users/GET_USERS', {}, { root: true });
    dispatch('projects/GET_PROJECTS', {}, { root: true });
    dispatch('customers/GET_CUSTOMERS', {}, { root: true });
    dispatch('projects/QUERY_PROJECTS', { completed: false }, { root: true });
    dispatch('FETCH_METADATA');
  },

  async FETCH_METADATA({ commit }) {
    const query = collection(fstore, 'metadata');
    docListener = await onSnapshot(query, (querySnap) => {
      const metadata = {};
      for (const doc of querySnap.docs) {
        const data = doc.data();
        switch (doc.id) {
          case 'tickets':
            metadata[doc.id] = data;
            break;
          default:
            metadata[doc.id] = data.count;
        }
      }
      commit('setMetadata', metadata);
    });
  },

  async ADD_ERROR({ commit }, error) {
    commit('setError', error);
  },

  async CLEAR_ERROR({ commit }) {
    commit('setError', null);
  },

  async CLEAR_LISTENER() {
    if (docListener !== null) {
      await docListener();
      docListener = null;
    }
  },
};

const mutations = {
  setError: (state, error) => {
    state.error = error;
  },
  setMetadata: (state, metadata) => {
    state.metadata = metadata;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
