import { bomCollection } from '@/services/firebase/db';

import {
  doc,
  getDocs,
  query,
  onSnapshot,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';

import { checkUID20, bomStatus } from '@/utilities';

// userStore
const state = {
  isLoading: true,
  bomID: null,
  bomData: {},
  boms: [],
  error: null,
};

// global scoped listener var so that we can detatch later
let docListener = null;

const getters = {
  GET_BOMS: (state) => state.boms,
  GET_BOM: (state) => state.bomData,
  IS_LOADING: (state) => state.isLoading,
};

const actions = {
  async GET_BOMS({ commit }) {
    try {
      commit('SET_LOADING', true);
      const q = query(bomCollection);
      const docSnap = await getDocs(q);
      const boms = docSnap.docs.map((doc) => doc.data());
      commit('SET_LOADING', false);
      commit('SET_BOMS', boms);
    } catch (error) {
      console.log(error);
      commit('SET_ERROR', error);
    }
  },

  async GET_BOM({ state, commit, dispatch }, docID) {
    commit('SET_LOADING', true);
    if (docListener) {
      docListener(); // kill old listener before attaching new one
      dispatch('CLEAR_BOM');
    }
    if (!docID && !checkUID20(docID)) {
      // docID is a UUID from firestore
      return dispatch('app/ADD_ERROR', new Error('Missing UID'), {
        root: true,
      });
    }

    // No Need to fetch data, doc listener is available
    if (state.bomID === docID) return;

    commit('SET_BOM_UID', docID);
    try {
      const docRef = doc(bomCollection, docID);
      docListener = onSnapshot(docRef, (doc) => {
        commit('SET_BOM_DATA', doc.data());
        commit('SET_LOADING', false);
        return Promise.resolve();
      });
    } catch (error) {
      dispatch('app/ADD_ERROR', error, {
        root: true,
      });
    }
  },

  async UPDATE_BOM({ dispatch }, { bomID, update }) {
    if (!bomID && !checkUID20(bomID)) {
      // docID is a UUID from firestore
      return dispatch('app/ADD_ERROR', new Error('Missing UID'), {
        root: true,
      });
    }

    if (update.materials) {
      const status = await bomStatus(update.materials);
      update = { ...update, ...status };
    }

    try {
      const docRef = doc(bomCollection, bomID);
      await updateDoc(docRef, update);
    } catch (error) {
      dispatch('app/ADD_ERROR', error, {
        root: true,
      });
    }
  },

  // async CREATE_PROJECT({
  //   commit,
  //   dispatch,
  //   rootGetters
  // }, project) {
  //   if (!project) {
  //     // TODO: Clean Up error handling
  //     commit('SET_ERROR', new Error('Missing Project'))
  //     return null
  //   }
  //   try {
  //     const userId = rootGetters['users/user'].uid
  //     const projectRef = await projectCollection.doc()
  //     project.created = ts
  //     project.createdBy = userId
  //     project.uid = projectRef.id
  //     if (project.pm && project.pm.length === 28) {
  //       // set pm as original watcher
  //       project.watchers = [project.pm]
  //     }
  //     projectRef.set(project)
  //     dispatch('GET_PROJECTS')
  //   } catch (error) {
  //     commit('SET_ERROR', new Error(error))
  //   }
  // },

  async DELETE_BOM({ commit, dispatch }, docID) {
    if (!docID || docID.length < 8) {
      commit('SET_ERROR', 'Firebase UUID Required');
    }
    try {
      const docRef = doc(bomCollection, docID);
      await deleteDoc(docRef);
      dispatch('GET_BOMS');
    } catch (error) {
      commit('SET_ERROR', new Error(error));
    }
  },

  async CLEAR_BOM({ commit }) {
    try {
      commit('CLEAR_BOM_DATA');
    } catch (error) {
      commit('SET_ERROR', new Error(error));
    }
  },
};

const mutations = {
  SET_LOADING: (state, loading) => {
    state.isLoading = loading;
  },
  SET_BOMS: (state, boms) => {
    state.boms = boms;
  },
  SET_BOM_UID: (state, bomID) => {
    state.bomID = bomID;
  },
  SET_BOM_DATA: (state, bomData) => {
    state.bomData = bomData;
  },
  CLEAR_BOM_DATA: (state) => {
    docListener();
    state.bomID = null;
    state.bomData = {};
  },
  SET_ERROR: (state, error) => {
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
