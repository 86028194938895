import { format, formatDistanceToNowStrict } from 'date-fns';

// Misc Helper Utilities

// Firebase UUID AUTH Length ex: vT3pouERhDKsiej9H7sO
export const checkUID28 = (uid) => typeof uid === 'string' && uid.length === 28;
// Firebase UUID for Doc() ex: SQqp2kZy75nQ38o9h7lB
export const checkUID20 = (uid) => typeof uid === 'string' && uid.length === 20;

// Misc Helper Utilities
export const groupBy = (objectArray, property) => {
  return objectArray.reduce(function (acc, obj) {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
};

export const debounce = function (fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    const args = arguments;
    const that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

export const maybePluralize = (count, noun, suffix = 's') => {
  return `${count} ${noun}${count !== 1 ? suffix : ''}`;
};

export const percentageToColor = (percentage, maxHue = 120, minHue = 0) => {
  const hue = percentage * (maxHue - minHue) + minHue;
  return `hsl(${hue}, 50%, 30%)`;
};

export const randomColor = (string, saturation = 30, lightness = 80) => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = hash % 360;
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

// Filters

// percentage Number => 12.22% : string
export const percentage = (number) => {
  if (typeof number === 'number') {
    const formated = number.toLocaleString('en-us', {
      maximumFractionDigits: 2,
    });
    return formated + '%';
  } else {
    return 'N/A';
  }
};

// Clean Nulls from Object
export const removeNullProperties = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([, v]) => v !== null));

// timeAgo Timestamp/Date => 5 min ago : string
export const timeAgo = (date, units) => {
  if (!date) return '';
  switch (typeof date) {
    case 'object': {
      if (!units) {
        return formatDistanceToNowStrict(date.toDate(), {
          addSuffix: true,
        });
      }
      return formatDistanceToNowStrict(date.toDate(), {
        addSuffix: true,
        unit: units,
      });
    }

    case 'string': {
      const valid = new Date(date).getTime();
      if (!valid) return date;
      // TODO: Add a check for timestamps saved as an epoch string and handle correctly
      return formatDistanceToNowStrict(date, {
        addSuffix: true,
      });
    }

    case 'number': {
      const valid = new Date(date).getTime();
      if (!valid) return date;
      // TODO: Add a check for timestamps saved as an epoch string and handle correctly
      return formatDistanceToNowStrict(date, {
        addSuffix: true,
      });
    }
  }
};

export const dateToString = (date, dateFormat = 'MMMM do yyyy') => {
  if (!date) return '';
  switch (typeof date) {
    case 'object': {
      if (date instanceof Date) return format(date, dateFormat);
      return format(date.toMillis(), dateFormat);
    }

    case 'string': {
      const valid = new Date(date).getTime();
      if (!valid) return date;
      return format(new Date(date), dateFormat);
    }
  }
};

// 21-1999 => 21.1999
export const parseSortID = (string) => {
  const id = string.split('-').filter((s) => !isNaN(s));
  return Number(`${id[0]}.${id[1]}${id[2] || ''}`);
};

export const bomStatus = (materialsArray) => {
  const total = materialsArray.length;
  const approved = [...materialsArray].filter((i) => i.approved).length || 0;
  const ordered = [...materialsArray].filter((i) => i.ordered).length || 0;
  const stock = [...materialsArray].filter((i) => i.stock).length || 0;
  let status = 0;
  // partially approved
  if (approved > 0) {
    status = 1;
  }
  // approved
  if (approved === total) {
    status = 2;
  }
  // partially ordered
  if (approved === total && ordered > 0) {
    status = 3;
  }
  // ordered
  if (approved === total && ordered === total) {
    status = 4;
  }
  // awaiting arrival
  if (approved === total && ordered === total && stock > 0) {
    status = 5;
  }
  // awaiting arrival
  if (approved === total && ordered === total && stock === total) {
    status = 6;
  }

  // TODO: Check into switch true for readability
  // switch (true) {
  //   case value:
  //     break;
  //   default:
  //     break;
  // }

  return {
    approved,
    ordered,
    stock,
    status,
  };
};
