import GuestLayout from '@/layout/GuestLayout';

const routes = [
  {
    path: '/guest',
    component: GuestLayout,
    children: [
      {
        name: 'GuestDashboard',
        path: 'dashboard',
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/views/guest/GuestDashboard'),
      },
      {
        name: 'GuestView',
        path: 'project/:projectId',
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/views/projects/Export'),
      },
    ],
  },
];

export default routes;
