import { systemsCollection } from '@/services/firebase/db';

import { checkUID20 } from '@/utilities';

import {
  doc,
  query,
  onSnapshot,
  deleteDoc,
  getDocs,
  setDoc,
  orderBy,
  serverTimestamp,
} from 'firebase/firestore';

const state = {
  systems: [],
  system: {},
  error: null,
};

const getters = {
  GET_PROJECT_SYSTEMS: (state) => state.systems,
  GET_SYSTEM: (state) => state.system,
};

let systemListener = null;

const actions = {
  async GET_SYSTEMS({ commit, dispatch, rootGetters }, projectID) {
    // confirm that the uid exists and is a firebase id
    if (!projectID || !checkUID20(projectID)) {
      commit('SET_ERROR', 'Firebase UUID Required');
    }
    const currentProject = rootGetters['projects/getProjectId'];
    // exit function if listener is already active
    if (systemListener && currentProject === projectID) return;

    // clear state and detatch listener
    dispatch('CLEAR_SYSTEMS');

    try {
      const q = query(systemsCollection(projectID), orderBy('order'));
      systemListener = onSnapshot(q, (querySnap) => {
        commit('SET_SYSTEMS', querySnap);
      });
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  async GET_SYSTEM({ commit }, { projectID, systemID }) {
    if (!projectID || !checkUID20(projectID)) {
      commit('SET_ERROR', 'Firebase UUID Required');
      return;
    }

    try {
      const ref = await doc(systemsCollection(projectID), systemID);
      const system = ref.data();
      commit('SET_SYSTEM', system);
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  async CREATE_SYSTEM({ commit, rootGetters }, system) {
    // Check that item object exists in order to write to new doc
    if (!system) {
      commit('SET_ERROR', 'Object Required to Create System');
    }
    // Check for Firestore ID and that ID !== Project number
    if (!system.projectID || !checkUID20(system.projectID)) {
      commit('SET_ERROR', 'Firebase UUID Required for System');
    }

    try {
      system.createdBy = rootGetters['users/GET_USER_ID'];
      system.created = serverTimestamp();

      // TODO: make this dynamic and pulled from config
      const categories = [
        'power',
        'addressing',
        'roughInstall',
        'network',
        'pointToPoint',
        'graphics',
        'functionTesting',
      ];
      if (system.equipment) {
        for (const equipment in system.equipment) {
          const obj = { current: 0, projected: 0 };
          system.equipment[equipment].startDate = system.startDate;
          system.equipment[equipment].dueDate = system.dueDate;
          for (const cat of categories) {
            system.equipment[equipment][cat] = obj;
          }
        }
      }

      const q = query(systemsCollection(system.projectID));
      const querySnap = await getDocs(q);
      system.order = querySnap.size + 1 || 999;
      const docRef = doc(systemsCollection(system.projectID));
      system.id = docRef.id;
      await setDoc(docRef, system);
      // await projectCollection.doc(system.projectID).collection('systems').doc(system.id).set(system)
      // dispatch('GET_PROJECT_SYSTEMS', system.projectID)
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  async UPDATE_ITEM() {
    // console.log(item)
    // // Check that item object exists in order to write to new doc
    // if (!item) {
    //   commit('SET_ERROR', 'Firebase UUID Required for Punchlist')
    // }
    // // Check for Firestore ID and that ID !== Project number
    // if (!item.projectID || item.projectID.length < 8) {
    //   commit('SET_ERROR', 'Firebase UUID Required for Punchlist')
    // }
    // try {
    //   const {
    //     projectID,
    //     uid
    //   } = item
    //   const itemRef = await projectCollection.doc(projectID).collection('punchList').doc(uid)
    //   item.updated = ts
    //   item.updatedBy = rootState.user.uid
    //   if (item.complete) {
    //     item.completed = ts
    //     item.completedBy = rootState.user.uid
    //   } else {
    //     item.completed = null
    //     item.completedBy = null
    //   }
    //   await itemRef.set(item, {
    //     merge: true
    //   })
    //   dispatch('GET_PUNCHLIST_ITEMS', item.projectID)
    // } catch (error) {
    //   commit('SET_ERROR', error)
    // }
  },

  async DELETE_SYSTEM({ commit }, system) {
    // Check that data exists and Firestore ID and that ID !== Project number
    if (!system || !system.projectID || !system.id) {
      commit('SET_ERROR', 'Firebase UUID Required for Punchlist');
    }

    try {
      const { projectID, id } = system;
      const ref = doc(systemsCollection(projectID), id);
      await deleteDoc(ref);
      // await projectCollection.doc(projectID).collection('systems').doc(id).delete()
      // dispatch('GET_PROJECT_SYSTEMS', projectID)
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  async CLEAR_SYSTEMS({ commit }) {
    if (systemListener) systemListener();
    commit('CLEAR_SYSTEMS');
  },
};

const mutations = {
  SET_SYSTEMS: (state, querySnap) => {
    const changes = querySnap.docChanges();
    changes.forEach((change) => {
      if (change.oldIndex !== -1) {
        state.systems.splice(change.oldIndex, 1);
      }
      if (change.newIndex !== -1) {
        state.systems.splice(
          change.newIndex,
          0,
          Object.freeze(change.doc.data())
        );
      }
    });
  },
  SET_SYSTEM: (state, system) => {
    state.system = system;
  },
  CLEAR_SYSTEMS: (state) => {
    state.systems = [];
  },
  SET_ERROR: (state, error) => {
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
