import { punchListCollection } from '@/services/firebase/db';
import {
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  where,
} from '@firebase/firestore';
import { checkUID20, checkUID28 } from '@/utilities';

// userStore
const state = {
  items: [],
  userItems: [],
  item: {},
  error: null,
};

const getters = {
  GET_PUNCHLIST_ITEMS: (state) => state.items,
  GET_USER_ITEMS: (state) => state.userItems,
  GET_ITEM: (state) => (id) => state.items.find(({ uid }) => uid === id),
};

let itemListener = null;

const actions = {
  async GET_PUNCHLIST_ITEMS({ commit, rootGetters }, projectID) {
    // Check that projectID is a UUID from firebase
    if (!projectID || !checkUID20(projectID))
      return commit('SET_ERROR', 'Firebase UUID Required');

    const currentProject = rootGetters['projects/getProjectId'];
    // exit function if listener is already active
    if (itemListener && currentProject === projectID) return;
    // projectID is new, detach listener
    if (itemListener) itemListener();
    commit('CLEAR_ITEMS');

    try {
      const q = query(punchListCollection(projectID));
      itemListener = onSnapshot(q, (querySnap) => {
        commit('SET_ITEMS', querySnap);
      });
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  async GET_ITEMS_FOR_USER({ commit, rootGetters }, userID) {
    if (!userID) {
      // default to logged in users task list
      userID = rootGetters['users/GET_USER_ID'];
    }
    if (!checkUID28(userID)) {
      commit('SET_ERROR', 'Firebase UserID Required');
      return;
    }

    try {
      const q = query(
        punchListCollection(),
        where('assigned', '==', userID),
        where('complete', '==', false)
      );
      const querySnap = await getDocs(q);
      const punchlist = querySnap.docs.map((doc) => doc.data());
      commit('SET_USER_ITEMS', punchlist);
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  async CREATE_ITEM({ commit, rootGetters }, item) {
    if (!item) return;
    // Check for Firestore ID and that ID !== Project number
    if (!item.projectID || !checkUID20(item.projectID)) {
      commit('SET_ERROR', 'Firebase UUID Required');
    }

    try {
      const docRef = doc(punchListCollection(item.projectID));
      item.created = serverTimestamp();
      item.createdBy = rootGetters['users/GET_USER_ID'];
      item.uid = docRef.id;
      await setDoc(docRef, item);
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  async UPDATE_ITEM({ commit, rootGetters }, item) {
    console.log(item);
    if (!item) return;
    // Check for Firestore ID and that ID !== Project number
    if (!item.projectID || !checkUID20(item.projectID)) {
      commit('SET_ERROR', 'Firebase UUID Required');
    }

    try {
      const { projectID, uid } = item;
      const userID = rootGetters['users/GET_USER_ID'];
      const docRef = doc(punchListCollection(projectID), uid);
      // const itemRef = await projectCollection.doc(projectID).collection('punchList').doc(uid)
      item.updated = serverTimestamp();
      item.updatedBy = userID;
      if (item.complete) {
        item.completed = serverTimestamp();
        item.completedBy = userID;
      } else {
        item.completed = null;
        item.completedBy = null;
      }
      await setDoc(docRef, item, {
        merge: true,
      });
      // await docRef.set()
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  async DELETE_ITEM({ commit }, item) {
    // Check that data exists and Firestore ID and that ID !== Project number
    if (!item || !item.projectID || item.projectID.length < 8 || !item.uid) {
      commit('SET_ERROR', 'Firebase UUID Required for Punchlist');
    }

    try {
      const { projectID, uid } = item;
      const docRef = doc(punchListCollection(projectID), uid);
      await deleteDoc(docRef);
      // await projectCollection.doc(projectID).collection('punchList').doc(uid).delete()
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  async CLEAR_ITEMS({ commit }) {
    if (itemListener) itemListener();
    commit('CLEAR_ITEMS');
  },
};

const mutations = {
  SET_ITEMS: (state, querySnap) => {
    const changes = querySnap.docChanges();
    changes.forEach((change) => {
      if (change.oldIndex !== -1) {
        state.items.splice(change.oldIndex, 1);
      }
      if (change.newIndex !== -1) {
        state.items.splice(
          change.newIndex,
          0,
          Object.freeze(change.doc.data())
        );
      }
    });
  },
  SET_USER_ITEMS: (state, items) => {
    state.userItems = items.map((item) => Object.freeze(item));
  },
  CLEAR_ITEMS: (state) => {
    state.items = [];
  },
  SET_ERROR: (state, error) => {
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
